.testimonials {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem 2rem;
}

.wrapper {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: flex-end;
    width: 100%;
}

.wrapper>img {
    width: 25rem;
    justify-self: center;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.container>:nth-child(1) {
    font-size: 2rem;
    display: block;
    text-transform: uppercase;
    font-weight: bold;
}

.container>:nth-child(2) {
    font-size: 1rem;
    display: block;
    text-transform: uppercase;
}

.wrapper>:nth-child(3) {
    text-align: right;
}

.reviews {
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
}

.carousal {
    width: 100%;
}

.tCarousal {
    padding: 2rem;
}

.testimonial {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #fff;
    border-radius: 0.5rem;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    -ms-border-radius: 0.5rem;
    -o-border-radius: 0.5rem;
}

.testimonial>img {
    position: absolute;
    width: 3rem;
    top: -1.5rem;
    left: 45%;
}

.testimonial>:nth-child(2) {
    text-align: center;
    font-size: 0.8rem;
    letter-spacing: 1px;
    margin-top: 2rem;
}

.testimonial>hr {
    height: 1px;
    width: 80%;
    background-color: rgb(136, 134, 134);
    border: none;
}