@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,500;1,600;1,700&display=swap');

body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    background: var(--bg);
    color: var(--black);
    transition: all 3ms ease;
    scroll-behavior: smooth;
    -webkit-transition: all 3ms ease;
    -moz-transition: all 3ms ease;
    -ms-transition: all 3ms ease;
    -o-transition: all 3ms ease;
}