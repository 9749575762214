.s_container {
    position: relative;
}

.s_container .swiper {
    width: 70%;
    height: 12rem;
    position: unset;
}

.s_container .swiper-slide {
    background: white;
    display: flex;
    border-radius: 10px;
    padding: 1rem;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.s_container .swiper-button-prev {
    left: 6rem;
}

.s_container .swiper-button-next {
    right: 6rem;
}

.s_container .swiper-button-prev,
.s_container .swiper-button-next {
    color: black;
}

.left_s {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.name {
    display: flex;
    flex-direction: column;
}

.name>:nth-child(1) {
    font-size: 1.4rem;
    font-weight: 600;
}

.name>:nth-child(2) {
    font-size: 0.7rem;
}

.left_s>:nth-child(2) {
    font-style: 2rem;
    font-weight: bold;
}

.left_s>:nth-child(3) {
    font-size: 0.6rem;
    padding: 5px 10px;
    border: 1px solid var(--black);
    border-radius: 15px;
    width: max-content;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.left_s>:nth-child(3):hover {
    border: none;
    background-color: skyblue;
    cursor: pointer;
    color: white;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
}

.img-p {
    transform: rotate(-20deg);
    position: absolute;
    right: 0;
    width: 40%;
    height: 100%;
    bottom: -20%;
    -webkit-transform: rotate(-20deg);
    -moz-transform: rotate(-20deg);
    -ms-transform: rotate(-20deg);
    -o-transform: rotate(-20deg);
}

@media screen and (max-width:856px) {

    .s_container,
    .swiper {
        width: 90%;
        height: 10rem;
    }

    .s_container .swiper-button-prev,
    .s_container .swiper-button-next {
        display: none;
    }
}